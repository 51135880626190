import React, { useState, useRef, useEffect, useContext } from 'react'
import {
  Box,
  Typography,
  Divider,
  Link,
  TextField,
  Button,
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { CircularProgress } from '@mui/material'
import userIcon from '../../assets/images/user-icon2.svg'
import userContact from '../../assets/images/user-contact.png'
import EditPencil from '../../assets/icons/create.svg'
import { PersonalInfoWrapper } from './style'
import { useDispatch, useSelector } from 'react-redux'
import {
  editUserInfo,
  removeAccount,
  removeMessage,
  verifyMobileNumOtp,
} from '../../redux/settings'
import { removeAuthCookies } from 'esstart-components'
import { AuthContext, Loader } from 'esstart-components'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { successToast, errorToast } from '../toasts'
import loaderSpinner from '../../assets/images/esstart_spinner.gif'

const useStyles = makeStyles({
  badge: {
    height: '35px !important',
    width: '35px !important',
    borderRadius: '50px !important',
    cursor: 'pointer',
  },
})

const PersonalInfoTab = () => {
  const dispatch = useDispatch<any>()
  const classes = useStyles()
  const imageInputRef: any = useRef(null)

  const [isEditable, setIsEditable] = useState<null | string>(null)
  const [image, setImage] = useState<any>(null)
  const [openRemove, setOpenRemove] = useState(false)
  const [showDeletingLabel, setShowDeletingLabel] = useState(false)
  const { userInfoData } = useContext(AuthContext)
  const [isUserName, setUserName] = useState(userInfoData?.name)
  const [isUserPhone, setUserPhone] = useState(userInfoData?.phoneNumber)
  const [otpSent, setOtpSent] = React.useState(false)
  const [otp, setOtp] = React.useState('')

  const setting: any = useSelector((state: any) => state.settings)

  const onEdit = (name: string) => (event: React.SyntheticEvent) => {
    event.preventDefault()
    setIsEditable(name)
  }

  const handleUserName = (e: any) => {
    setUserName(e.target.value)
  }

  const handleUserPhone = (e: any) => {
    if (e) setUserPhone(e)
  }

  const onSave = () => {
    const value =
      isEditable === 'name'
        ? { name: isUserName }
        : { phoneNumber: isUserPhone }
    dispatch(editUserInfo(value))
    setIsEditable(null)
  }

  const handleSendOtp = async () => {
    const res = await dispatch(editUserInfo({ phoneNumber: isUserPhone }))
    if (res?.payload?.data?.status === 'success') {
      successToast('OTP sent successfully !')
    } else {
      errorToast('OTP not sent !')
    }
    setOtpSent(() => true)
  }

  const handleOtpChange = (otp: any) => {
    setOtp(otp.target.value)
    if (otp.target.value.length === 6) return false
  }

  const handleVerifyOtp = async () => {
    const res = await dispatch(verifyMobileNumOtp({ code: otp }))
    if (res?.payload?.status === 'success') {
      successToast('Phone number verified successfully !')
      setIsEditable(null)
      setOtpSent(false)
    } else {
      errorToast('Phone number not verified !')
    }
  }

  const onEditImage = () => {
    imageInputRef?.current?.click()
  }

  const handleImageChange = (event: any) => {
    const { files } = event.target
    const fileReader = new FileReader()
    fileReader.onload = function () {
      setImage(fileReader.result)
    }
    fileReader.readAsDataURL(files[0])
  }

  const onDelete = () => {
    setOpenRemove(true)
  }

  useEffect(() => {
    if (setting?.removeAccountData) {
      removeAuthCookies()
      window.location.href = `${window.location.origin}/login`
    }
  }, [setting])

  useEffect(() => {
    if (setting?.rejectUserInfo) {
      setTimeout(() => {
        dispatch(removeMessage())
      }, 3000)
    }
  }, [setting?.rejectUserInfo])

  const handleRemoveAccount = () => {
    dispatch(removeAccount())
    setShowDeletingLabel(true)
  }

  const handleClose = () => {
    setOpenRemove(false)
  }

  return (
    <PersonalInfoWrapper>
      <div
        style={{ display: setting?.loading ? 'flex' : 'none' }}
        // className="loader"
      >
        {/* <img src={loaderSpinner} alt="Loader" /> */}
        <Loader />
      </div>
      <Box sx={{ marginTop: '40px', maxWidth: '480px' }}>
        <Box sx={{ textAlign: 'center', marginBottom: '30px' }}>
          {/* <Badge
            classes={{ badge: classes.badge }}
            color="primary"
            overlap="circular"
            badgeContent={
              <img src={EditPencil} alt="pencil" className="pencil-icon" />
            }
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClick={onEditImage}
          >
            <img
              src={image || userIcon}
              alt="user-icon"
              className="profile-image"
            />
          </Badge>
          <input
            style={{ display: 'none' }}
            ref={imageInputRef}
            type="file"
            name="imgFileName"
            onChange={handleImageChange}
          /> */}
          <img
            src={image || userIcon}
            alt="user-icon"
            className="profile-image"
          />
          <Typography variant="h5">
            {userInfoData?.name || isUserName ? isUserName : 'Not Set'}
          </Typography>
          <Typography>{userInfoData?.email}</Typography>
        </Box>
        <Divider />
        <Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}
          >
            <img src={userContact} alt="full-name" />
            <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>
              Full Name
            </Typography>
          </Box>
          {isEditable === 'name' ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                textAlign: 'center',
                alignItems: 'center',
                marginTop: '6px',
                marginBottom: '20px',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <TextField
                defaultValue={isUserName}
                name="name"
                sx={{ flex: 3, width: '100%', paddingRight: '10px' }}
                onChange={handleUserName}
              />

              <Button
                variant="outlined"
                sx={{
                  flex: 1,
                  borderRadius: '25px',
                  width: { xs: '150px', sm: '100%' },
                  marginTop: { xs: '25px', sm: '0px' },
                }}
                onClick={onSave}
              >
                Save
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '6px',
                marginBottom: '20px',
              }}
            >
              <Typography sx={{ fontSize: '18px' }}>
                {userInfoData?.name || isUserName ? isUserName : 'Not Set'}
              </Typography>
              <Link href="#" color="#FF7200" onClick={onEdit('name')}>
                Edit
              </Link>
            </Box>
          )}
        </Box>
        <Divider />
        <Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}
          >
            <img src={userContact} alt="full-name" />
            <Typography sx={{ fontSize: '14px', marginLeft: '10px' }}>
              Mobile No
            </Typography>
          </Box>
          {isEditable === 'phoneNumber' ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  textAlign: 'center',
                  alignItems: 'center',
                  marginTop: '6px',
                  marginBottom: '20px',
                  flexDirection: { xs: 'row', sm: 'row' },
                }}
              >
                {/* <TextField
                defaultValue={isUserPhone}
                onChange={handleUserPhone}
                name="phoneNumber"
                sx={{ flex: 3, width: '100%', paddingRight: '10px' }}
              /> */}
                <div>
                  <PhoneInput
                    name="phoneNumber"
                    id="phone"
                    className="phone-no__input"
                    placeholder="Enter phone number"
                    international
                    defaultCountry="US"
                    countryCallingCodeEditable={false}
                    value={isUserPhone}
                    onChange={handleUserPhone}
                  />
                  {otpSent ? (
                    <div
                      className="float-end app-pri-color cursor-pointer"
                      onClick={handleSendOtp}
                    >
                      <small>Resend OTP</small>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {otpSent ? (
                  <></>
                ) : (
                  <Button
                    variant="outlined"
                    sx={{
                      flex: 1,
                      borderRadius: '25px',
                      width: { xs: '150px', sm: '100%' },
                      marginTop: { xs: '25px', sm: '0px' },
                    }}
                    onClick={handleSendOtp}
                  >
                    Send OTP
                  </Button>
                )}
              </Box>
              {otpSent ? (
                <div className="mt-3 flex-row align-items-center flex-wrap">
                  <div className="">
                    <input
                      className="otp-inp"
                      type="number"
                      maxLength={6}
                      onChange={handleOtpChange}
                      value={otp}
                      onInput={(e) =>
                        //@ts-ignore
                        (e.target.value = e.target.value.slice(0, 6))
                      }
                    />
                  </div>
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: '25px',
                      width: { xs: '150px', sm: '150px' },
                      marginTop: { xs: '25px', sm: '0px' },
                    }}
                    onClick={handleVerifyOtp}
                  >
                    Verify OTP
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '6px',
                marginBottom: '20px',
              }}
            >
              <Typography sx={{ fontSize: '18px' }}>
                {userInfoData?.phoneNumber || isUserPhone
                  ? isUserPhone
                  : 'Not Set'}
              </Typography>
              <Link href="#" color="#FF7200" onClick={onEdit('phoneNumber')}>
                Edit
              </Link>
            </Box>
          )}
        </Box>
        <Box>
          {setting?.rejectUserInfo && (
            <Typography sx={{ marginBottom: '20px', color: 'red' }}>
              {setting?.rejectUserInfo}
            </Typography>
          )}
          <Button
            variant="outlined"
            sx={{
              flex: 1,
              borderRadius: '25px',
              marginTop: '60px',
            }}
            onClick={onDelete}
          >
            Delete Account
          </Button>
        </Box>
      </Box>

      <Dialog
        open={openRemove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {showDeletingLabel
              ? 'Deleting Account... After this you will be redirect to login'
              : 'Are you sure you want to delete your account?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {showDeletingLabel ? (
            <CircularProgress />
          ) : (
            <>
              <Button onClick={handleRemoveAccount}>Yes</Button>
              <Button onClick={handleClose}>No</Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </PersonalInfoWrapper>
  )
}

export default PersonalInfoTab
