import styled from 'styled-components'

export const AddDeviceStyles = styled.div`
  #responsive-dialog-title span {
    float: right;
    display: block;
    width: 80%;
    height: 39px;
    font: normal normal 400 25px/39px Plus Jakarta Sans;

    letter-spacing: 0px;
    color: #202020;
    opacity: 1;
    text-align: left;
    margin-right: 15%;
  }

  #responsive-dialog-title span:first-child {
    text-align: right;
    width: 18px;
    height: 18px;
    background: transparent url('../../../assets/images/svg/dialog-close.svg')
      0% 0% no-repeat padding-box;
    opacity: 1;
    margin-top: 11px;
    margin-right: 1.89px;
    cursor: pointer;
  }

  #dialog-description {
    padding-top: 18px;
    padding-bottom: 41px;
    text-align: left;
    font: normal normal 400 16px/19px Plus Jakarta Sans;
    letter-spacing: 0px;
    color: #202020;
    opacity: 1;
  }

  .errorMsg {
    color: red;
  }

  #dialog-sub-description {
    text-align: left;
    font: normal normal 400 14px/18px Plus Jakarta Sans;
    letter-spacing: 0px;
    color: #202020;
    opacity: 1;
    padding-top: 10px;
  }

  #dialogBtnActions {
    justify-content: center;
    cursor: pointer;
  }

  #dialogBtnActions Button {
    background: #ff7200 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    width: 198px;
    height: 40px;
    color: #ffffff;
    margin: 0px;
  }

  .otp-container {
    text-align: left;
    font: normal normal 400 23px/28px Plus Jakarta Sans;
    letter-spacing: 0px;
    color: #202020;
    opacity: 1;
    // border-style: hidden;
    border-bottom: 1px solid #b5b5b5;
    background: #ffffff 0% 0% no-repeat padding-box;
    width: 45px !important;
    height: 44px;
    border-top: none;
    border-left: none;
    border-right: none;
  }

  .otp-focus-style {
    border-style: none;
    border-bottom: 1.9px solid #b5b5b5;
    outline: 0em;
    background: #ffffff 0% 0% no-repeat padding-box;
  }

  .alert-message {
    margin-top: -10px;
    margin-bottom: 18px;
  }

  .text-decoration-none-1 {
    padding: 0px;
  }

  .device-name {
    margin-top: 0.5rem;
    font-size: 1rem;
    padding: 1rem 0.5rem;
    border-radius: 3px;
    width: 100%;
  }
`
