import React, { SyntheticEvent } from 'react'
import QRCode from 'react-qr-code'
import { FormControl, Select, MenuItem } from '@mui/material'
import { errorToast, successToast } from '../toasts'
import { AuthContext } from 'esstart-components'
import { useSelector, useDispatch } from 'react-redux'
import { editUserInfo } from '../../redux/settings'

export const CommonQrWrapper = () => {
  const authWrapper: any = React.useContext(AuthContext)
  const products: any = useSelector((state: any) => state.products)
  const dispatch: any = useDispatch()

  const [customUrl, setCustomUrl] = React.useState({
    value: '',
    error: '',
  })

  React.useEffect(() => {
    if (authWrapper?.userInfoData?.commonQrUrl) {
      setCustomUrl((prev) => ({
        ...prev,
        value: authWrapper?.userInfoData?.commonQrUrl,
      }))
    }
  }, [authWrapper?.userInfoData?.commonQrUrl])

  const unUsedFilteredEvents = products?.productsList?.filter((event: any) => {
    const showDate = new Date(event?.showDate)
    const currentDate = new Date()
    const differenceInTime = currentDate.getTime() - showDate.getTime()
    const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24))

    return differenceInDays < 11 || event?.productStatus === 'test'
  })

  const changeConnectedQrCode = async (event: any) => {
    const res = await dispatch(
      editUserInfo({
        commonQrCodeId: authWrapper?.userInfoData?.commonQrCodeId,
        commonQrCodeAttachedWith: event.target.value,
      })
    )
    if (res?.payload?.data?.status === 'success') {
      authWrapper.setUserInfoData((prev: any) => ({
        ...prev,
        commonQrCodeAttachedWith: event.target.value,
      }))
      successToast('QR attached to new event successfully!')
    } else {
      errorToast('Something went wrong!')
    }
  }

  const changeToggleQrCodeUrl = (event: SyntheticEvent) => {
    const checked = (event.target as HTMLInputElement).checked
    authWrapper.setUserInfoData((prev: any) => ({
      ...prev,
      isCommonQrAttachedWithUrl: checked,
    }))
    dispatch(
      editUserInfo({
        isCommonQrAttachedWithUrl: checked,
      })
    )
  }

  const changeCommonQrCustomUrl = (e: SyntheticEvent) => {
    const value = (e.target as HTMLInputElement).value
    if (!value.startsWith('http')) {
      setCustomUrl((prev) => ({
        ...prev,
        error: 'Please enter a valid URL',
      }))
      return
    }
    setCustomUrl((prev) => ({
      ...prev,
      error: '',
    }))
    authWrapper.setUserInfoData((prev: any) => ({
      ...prev,
      commonQrUrl: value,
    }))
    dispatch(
      editUserInfo({
        commonQrUrl: value,
      })
    )
  }

  return (
    <>
      <p className="qr-heading txt-center">
        <b>Your Master QR Code</b>
      </p>
      <div className="qr-content">
        <div className="qr-img-container mt-1rem">
          <QRCode
            id="QRCode"
            size={500}
            style={{
              height: 'auto',
              maxWidth: '100%',
              width: '100%',
            }}
            value={`${process.env.REACT_APP_GUEST_WEBSITE_URL}?lookup=${authWrapper?.userInfoData?.commonQrCodeId}`}
            viewBox={`0 0 500 500`}
          />
        </div>
        <div className="mt-1rem">
          <div className="d-flex gap-4 align-items-center justify-content-center mb-1">
            <p>Attach QR to default URL?</p>
            <div className="toggle-radio flex-row gap-2 align-items-center">
              <label className="switch">
                <input
                  name="allowAutoDownload"
                  type="checkbox"
                  checked={
                    !!authWrapper?.userInfoData?.isCommonQrAttachedWithUrl
                  }
                  onChange={changeToggleQrCodeUrl}
                />
                <span className="slider round"></span>
              </label>
              YES
            </div>
          </div>
          {!!authWrapper?.userInfoData?.isCommonQrAttachedWithUrl ? (
            <div>
              <label className="common-qr-custom-url">
                <b>Default URL</b>
                <input
                  type="url"
                  className="app-input w-full"
                  placeholder="https://www.esstart.com/"
                  value={customUrl.value}
                  onChange={(e) =>
                    setCustomUrl((prev) => ({ ...prev, value: e.target.value }))
                  }
                  onBlur={changeCommonQrCustomUrl}
                />
                {!!customUrl.error && (
                  <small className="color-red">{customUrl.error}</small>
                )}
              </label>
            </div>
          ) : (
            <>
              <p className="txt-center">
                Attach this QR with any of your event by selecting from dropdown
              </p>
              <div className="txt-center">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    value={authWrapper?.userInfoData?.commonQrCodeAttachedWith}
                    onChange={changeConnectedQrCode}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {unUsedFilteredEvents?.length > 0 &&
                      unUsedFilteredEvents?.map((prd: any) => (
                        <MenuItem key={prd?._id} value={prd?.activityCode}>
                          {prd?.showName}: {prd?.activityCode}
                        </MenuItem>
                      ))}
                    {unUsedFilteredEvents?.length > 0 &&
                      unUsedFilteredEvents?.findIndex(
                        (prd: any) =>
                          prd?.activityCode ===
                          authWrapper?.userInfoData?.commonQrCodeAttachedWith
                      ) < 0 && (
                        <MenuItem
                          value={
                            authWrapper?.userInfoData?.commonQrCodeAttachedWith
                          }
                        >
                          Selected:{' '}
                          {authWrapper?.userInfoData?.commonQrCodeAttachedWith}
                        </MenuItem>
                      )}
                  </Select>
                </FormControl>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
