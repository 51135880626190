import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
// import './app.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import ReactGA from 'react-ga4'
import store from './redux/store'
import { AuthProvider } from 'esstart-components'
import Cookies from 'js-cookie'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ThemeWrapper } from './components/themes'

// ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID, {
//   gaOptions: {
//     userId: Cookies.get('userId') as string
//   }
// });
// ReactGA.pageview(window.location.pathname + window.location.search);
// ReactGA.initialize([
//   {
//     trackingId: String(process.env.REACT_APP_GA_MEASUREMENT_ID),
//     gaOptions: { userId: Cookies.get('userId') as string },
//   },
// ])
// ReactGA.send({
//   hitType: 'pageview',
//   page: window.location.pathname + window.location.search,
// })

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Provider store={store}>
      <AuthProvider>
        <Router>
          <ThemeWrapper>
            <App />
          </ThemeWrapper>
        </Router>
      </AuthProvider>
    </Provider>
  </LocalizationProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
