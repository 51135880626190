import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Box,
  Alert,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
// import './add-device.scss'
import OtpInput from 'react-otp-input'
import { addDevice, clearMessage, editDevice } from '../../../redux/mydevices'
import { AddDeviceStyles } from './style'

export interface DialogProps {
  open: boolean
  onClose: () => void
  otpCode?: string
  closeAndAddName?: any
  toggleNameModal?: boolean
  deviceId?: any
  setDeviceId?: any
}

const OTP_LENGTH = 6

export const AddDevice = (props: DialogProps) => {
  const dispatch = useDispatch<any>()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const {
    onClose,
    open,
    otpCode,
    closeAndAddName,
    toggleNameModal,
    deviceId,
    setDeviceId,
  } = props
  const [otp, setOtp] = React.useState('')
  const [addDeviceClicked, setAddDeviceClicked] = React.useState(false)
  const { creatingDevice, responseStatus, message } = useSelector(
    (store: any) => store.myDevices
  )
  const [alert, setAlert] = React.useState(false)
  const [alertContent, setAlertContent] = React.useState('')
  const [isDeviceName, setDeviceName] = React.useState('')
  const [isDeviceError, setDeviceError] = React.useState(false)
  const [isOtpError, setOtpError] = React.useState(false)

  const handleClose = () => {
    onClose()
    setOtp('')
    setAddDeviceClicked(false)
  }
  const handleCloseAndAddName = () => {
    closeAndAddName()
    setOtp('')
    setAddDeviceClicked(false)
  }

  const addSmartDevice = async (otp: string) => {
    if (otp.length === OTP_LENGTH) {
      setDeviceError(false)
      // setOtpError(true)
      setAddDeviceClicked(true)
      const response = await dispatch(addDevice({ handshakeCode: otp }))
      setDeviceId(response.payload.data.deviceId)
    }
    if (otp.length !== OTP_LENGTH) {
      // console.log(otp.length, '----')
      // console.log(OTP_LENGTH, '--@@@@@@@@@@@@@@@@@@@@@--')
      setOtpError(true)
    }
    if (!isDeviceName) {
      setDeviceError(true)
    }
  }

  React.useEffect(() => {
    if (addDeviceClicked && !creatingDevice && responseStatus === 'success') {
      // dispatch(getMyDevices())
      handleCloseAndAddName()
    }
  }, [addDeviceClicked, creatingDevice, dispatch, responseStatus])

  React.useEffect(() => {
    if (responseStatus === 'error' && message) {
      setAlert(responseStatus)
      setAlertContent(message)
    } else {
      setAlert(false)
      setAlertContent('')
    }
  }, [responseStatus, alert, alertContent, message])

  React.useEffect(() => {
    if (alert && alertContent) {
      setTimeout(() => {
        dispatch(clearMessage())
      }, 4000)
    }
  }, [alert, alertContent, dispatch])

  React.useEffect(() => {
    if (otpCode && open) {
      setOtp(otpCode)
      addSmartDevice(otpCode)
    }
  }, [otpCode, open])

  const handleOtpChange = (otp: any) => {
    setOtp(otp)
  }

  const handleOnChange = (e: any) => {
    setDeviceName(e.target.value)
    if (!isDeviceName) {
      setDeviceError(true)
    } else {
      setDeviceError(false)
    }
  }

  const addDeviceName = () => {
    // dispatch(getMyDevices())
    dispatch(editDevice({ deviceName: isDeviceName, deviceId }))
    handleClose()
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <AddDeviceStyles>
        <DialogTitle id="responsive-dialog-title">
          <span style={{ fontSize: '32px' }} onClick={handleClose}></span>{' '}
          <span>
            {toggleNameModal ? 'Add Smart Device Name' : 'Add Smart Device'}
          </span>
        </DialogTitle>
        {toggleNameModal ? (
          <>
            <DialogContent
              style={{ height: '180px', width: '550px', overflow: 'hidden' }}
            >
              <DialogContentText id={`dialog-description`}>
                Enter the name for your smart device
              </DialogContentText>
              <DialogContentText id={`dialog-sub-description`}>
                Name
              </DialogContentText>
              <Box component="div">
                <input
                  type="text"
                  className="device-name"
                  placeholder="Enter device name"
                  onChange={handleOnChange}
                />
              </Box>
            </DialogContent>
            <DialogActions id={`dialogBtnActions`}>
              <Button onClick={addDeviceName}>Continue</Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent
              style={{ height: '180px', width: '550px', overflow: 'hidden' }}
            >
              <DialogContentText id={`dialog-description`}>
                Enter the 6 digit code displayed on your TV Screen to authorize
              </DialogContentText>
              {alert && (
                <Alert
                  severity={`${
                    responseStatus === 'success' ? 'success' : 'error'
                  }`}
                >
                  {message}
                </Alert>
              )}

              <DialogContentText id={`dialog-sub-description`}>
                Code
              </DialogContentText>
              <Box component="div">
                <OtpInput
                  value={otp}
                  onChange={handleOtpChange}
                  numInputs={OTP_LENGTH}
                  isInputNum={true}
                  focusStyle={`otp-focus-style`}
                  inputStyle={`otp-container`}
                  shouldAutoFocus={true}
                  separator={<span style={{ width: '10px' }}></span>}
                />
                {isOtpError && (
                  <>
                    <p className="errorMsg">Please Enter Otp </p>
                  </>
                )}
              </Box>
            </DialogContent>
            <DialogActions id={`dialogBtnActions`}>
              <Button
                onClick={() => addSmartDevice(otp)}
                disabled={creatingDevice}
              >
                {creatingDevice ? 'Adding Device..' : 'Add Device'}
              </Button>
            </DialogActions>
          </>
        )}
      </AddDeviceStyles>
    </Dialog>
  )
}
