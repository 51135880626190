import styled from 'styled-components'

export const AppStyles = styled.div`
  .cursor-pointer {
    cursor: pointer;
  }
  .color-red {
    color: red;
  }
  .mt-1rem {
    margin-top: 1rem;
  }

  .app-pri-btn {
    background-color: rgb(255, 114, 0);
    color: rgb(255, 255, 255);
    border-radius: 20px;
    border: 0;
    outline: 0;
    cursor: pointer;
  }
  .app-sec-btn {
    color: rgb(255, 114, 0);
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    border: 0;
    outline: 0;
    cursor: pointer;
  }

  .flex-grow-1 {
    flex-grow: 1;
  }

  .app-input {
    padding: 16.5px 14px;
    border-radius: 4px;
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.23);
  }

  .app-input:focus-visible {
    border: 1px solid #ff7200;
    outline: 1px solid #ff7200;
  }

  .toggle-radio .switch {
    height: 28px !important;
  }
  .toggle-radio .slider:before {
    left: 4px !important;
    bottom: 4px !important;
  }
  .toggle-radio input:checked + .slider:before {
    left: 0 !important;
  }
  .toggle-radio input:checked + .slider {
    background-color: #009fb7 !important;
  }
  .toggle-radio input:checked + .slider:before {
    background-color: #ffffff !important;
  }

  .membership-banner {
    border-radius: 7px;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    height: 6rem;
    background-color: #f7b117;
    overflow: hidden;

    .h-100 {
      height: 100%;
    }

    .blob {
      background-color: #ff7200;
      width: 8rem;
      height: 100%;
      border-radius: 0% 72% 68% 0% / 0% 60% 68% 0%;
      box-shadow: rgba(0, 0, 0, 0.35) 8px 0px 10px;

      .disc-num {
        font-size: 1.65rem;
        font-weight: 900;
      }

      .content {
        font-size: 1.125rem;
        font-weight: 600;
        margin-left: 1rem;
        transform: rotate(-20deg);
      }

      .color-white {
        color: #fff;
      }
    }
    .para-1-txt {
      font-weight: 600;
    }
    .para-2-txt {
      font-weight: 900;
      color: #fff;
      font-size: 1.9rem;
      text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
    }
    .blob-2 {
      background-color: #eaebeb;
      height: 100%;
      width: 3rem;
      border-radius: 86% 31% 11% 100% / 100% 0% 0% 40%;
    }
    .bg-white {
      background-color: #eaebeb;
    }
    .membership-txt {
      font-size: 1.2rem;
      height: 100%;
    }
    .para-4-txt {
      background-color: #10994a;
      color: #fff;
      display: inline-block;
      padding: 3px 6px;
    }
    .blob-3 {
      margin: -1px;
      height: 100%;
      width: 3rem;
      background-color: #eaebeb;
      clip-path: polygon(0 0, 100% 0%, 60% 105%, 0% 100%);
    }
    .ml-1 {
      margin-left: 1rem;
    }
    .mxy-1 {
      margin: 0 1rem;
    }

    .buy-membership-btn {
      background-color: rgb(255, 255, 255);
      color: rgb(0, 0, 0);
      text-decoration: none;
      text-transform: uppercase;
      padding: 0.75rem 3rem;
      font-size: 1rem;
      border: 0;
      border-radius: 20px;
      box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
        rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
    }

    @media screen and (max-width: 1400px) {
      font-size: 1rem;
    }
    @media screen and (max-width: 1200px) {
      .para-12 {
        display: none;
      }
      .blob-2 {
        display: none;
      }
      .membership-txt {
        padding-left: 1rem;
        margin-left: 2rem;
      }
    }
    @media screen and (max-width: 1000px) {
      .membership-txt {
        background-color: transparent;
        font-size: 1rem;
        padding: 0 1rem;
        margin: 0;
      }
      .blob-3,
      .first-div {
        display: none;
      }
      .second-div {
        flex-grow: 1;
      }
      .btn-wrapper {
        margin-left: auto;
      }
    }
    @media screen and (max-width: 550px) {
      height: fit-content;
      .para-4-txt {
        padding: 3px;
        margin-top: 4px;
      }
      .second-div {
        flex-wrap: wrap;
      }
      .membership-txt {
        padding: 1rem;
      }
      .btn-wrapper {
        padding-bottom: 1rem;
      }
    }
  }
`
