import styled from 'styled-components'

export const DeviceListStyles = styled.div`
  .my-devices__device-box {
    max-width: 662px;
    min-height: 147px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 5px #00000029;
    opacity: 1;
    margin-bottom: 20px;
  }
  .my-devices__device-detail-list {
    padding: 0px !important;
  }
  .my-devices__device-icon {
    width: 49px;
    height: 49px;
    background: transparent url('../../../assets/icons/device.svg') 0% 0%
      no-repeat padding-box;
    opacity: 1;
    /*margin-left: 20px!important;
  margin-top: 15px!important;*/
  }
  .my-devices__device-detail {
    padding: 0px !important;
    padding-left: 20.5px !important;
    padding-top: 15px !important;
    padding-bottom: 18.5px !important;
  }
  .MuiListItemText-primary {
    text-align: left;
    /* font: normal normal normal 23px/28px Plus Jakarta Sans;*/
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    font-size: 23px !important;
    line-height: 28px !important;
    font-family: Plus Jakarta Sans !important;
    letter-spacing: 0px !important;
    color: #202020 !important;
    opacity: 1;
    padding-left: 14px !important;
  }
  .my-devices__divider {
    margin-left: 20.5px !important;
    margin-right: 20.5px !important;
    border-bottom: 1px solid #e6e6ea !important;
    opacity: 1 !important;
  }
  .MuiListItemText-secondary {
    text-align: left;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 19px !important;
    font-family: Plus Jakarta Sans !important;
    letter-spacing: 0px !important;
    color: #202020 !important;
    opacity: 1;
    padding-left: 14px !important;
  }
  .my-devices__paired-text .MuiListItemText-primary {
    text-align: left !important;
    font: normal normal normal 16px/19px Plus Jakarta Sans !important;
    letter-spacing: 0px !important;
    color: #202020 !important;
    opacity: 1 !important;
  }
  .my-devices__device-remove {
    text-align: left;
    font: normal normal 600 14px/18px Plus Jakarta Sans !important;
    letter-spacing: 0px;
    color: #009fb7;
    opacity: 1;
    width: 64px;
    height: 18px;
    cursor: pointer;
    margin-right: 18px !important;
  }
`
