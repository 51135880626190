import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  approveCommonSlideshowRequest,
  getAllProductList,
  getProducts,
  getSecurityNumber,
} from '../../redux/products'
import {
  Card,
  CardMedia,
  CardContent,
  PriceLable,
  CardFooter,
  Buttons,
  Loader,
  AuthContext,
} from 'esstart-components'
import { CommonQrWrapper } from '../dashboard/CommonQrWrapper'
import { DashboardModals } from '../dashboard/DashboardModals'
import './style.css'
import {
  getAllComboOffers,
  getAllStrProducts,
  getUserCreditsInfo,
  purchaseProduct,
} from '../../redux/payment'
import { FormControl, Select, MenuItem } from '@mui/material'
import { errorToast, successToast } from '../toasts'
import { editUserInfo } from '../../redux/settings'
import copyIcon from '../../assets/images/copy-icon.svg'

export const MembersOnly = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const [openRemove, setOpenRemove] = useState(false)
  const [isLoader, setIsLoader] = useState(false)
  const [commonSlideshowConfig, setCommonSlideshowConfig] = useState({
    show: false,
    selectedNumber: -1,
  })
  const authWrapper: any = React.useContext(AuthContext)

  const productsData: any = useSelector((state: any) => state.products)
  const paymentSlice = useSelector((store: any) => store?.payments)
  const settings: any = useSelector((state: any) => state.settings)

  const filteredSpotlightEvents = useMemo(() => {
    return productsData?.productsList?.filter(
      (event: any) => event?.distinctName === 'slideshow'
    )
  }, [productsData?.productsList])

  const useUserCreditsPlans = (showPurchased: boolean) => {
    return useMemo(() => {
      return paymentSlice?.strProductsList
        ?.map((prd: any) => {
          const dataFromDb = paymentSlice?.comboOffersData?.find(
            (plan: any) => plan?.id === prd?.priceMetadata?.dbId
          )
          const alreadyPurchasedArr = paymentSlice?.userCreditsData?.filter(
            (plan: any) => plan?.planId === dataFromDb?.planId
          )
          const combinedCreditsQuantity = alreadyPurchasedArr?.reduce(
            (acc: any, curr: any) => {
              acc = acc + (curr.quantity - curr.usedQuantity)
              return acc
            },
            0
          )
          const purchasedCreditsQuantity = alreadyPurchasedArr?.reduce(
            (acc: any, curr: any) => {
              acc = acc + curr.quantity
              return acc
            },
            0
          )
          const usedCreditsQuantity = alreadyPurchasedArr?.reduce(
            (acc: any, curr: any) => {
              acc = acc + curr.usedQuantity
              return acc
            },
            0
          )
          const filterCondition = showPurchased
            ? combinedCreditsQuantity > 0
            : !(combinedCreditsQuantity > 0)
          if (dataFromDb && filterCondition) {
            return {
              ...prd,
              ...dataFromDb,
              usedCreditsQuantity,
              purchasedCreditsQuantity,
            }
          }
        })
        ?.filter((x: any) => x)
    }, [
      paymentSlice?.userCreditsData,
      paymentSlice?.comboOffersData,
      paymentSlice?.strProductsList,
    ])
  }

  useEffect(() => {
    !productsData.allProductList.length && dispatch(getAllProductList())
    !productsData.productsList.length && dispatch(getProducts())
  }, [])

  useEffect(() => {
    dispatch(
      getAllStrProducts({
        hasMembership: !!authWrapper?.userInfoData?.hasMembership,
      })
    )
    dispatch(getUserCreditsInfo())
    dispatch(getAllComboOffers())
  }, [])

  useEffect(() => {
    setIsLoader(false)
  }, [openRemove])

  const memberMailerPrd = useMemo(() => {
    return productsData?.allProductList?.find(
      (prd: any) => prd?.distinctName === 'member-mailer'
    )
  }, [productsData?.allProductList])

  const memberMailerAddedPrd = useMemo(() => {
    return productsData?.productsList?.find(
      (prd: any) => prd?.distinctName === 'member-mailer'
    )
  }, [productsData?.productsList])

  const onQrDownload = () => {
    const svg = document.getElementById('QRCode')!
    const svgData = new XMLSerializer().serializeToString(svg)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')!
    const img = new Image()
    img.onload = () => {
      // 600 dimensions so that qr can have white margins
      canvas.width = 600
      canvas.height = 600
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, 600, 600)
      ctx.drawImage(img, 50, 50)
      const pngFile = canvas.toDataURL('image/png')
      const downloadLink = document.createElement('a')
      downloadLink.download = 'QRCode'
      downloadLink.href = `${pngFile}`
      downloadLink.click()
    }
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
  }

  const handleManageRedirection = (productData: any) => {
    window.location.href = productData?.relation
      ? `${productData?.prodcutMetadata?.manageURL}/${productData.relation}/${productData._id}`
      : `${productData?.prodcutMetadata?.manageURL}/${productData._id}`
  }

  const purchasePlan = async ({ planObj }: any) => {
    const checkoutSessionResponse = await dispatch(
      purchaseProduct({
        strCId: authWrapper?.userInfoData?.strCId,
        username: authWrapper?.userInfoData?.email,
        line_items: [{ price: planObj?.priceId, quantity: 1 }],
        success_url: `${process.env.REACT_APP_WEBAPP_URL}dashboard?payment=success`,
        cancel_url: `${process.env.REACT_APP_WEBAPP_URL}dashboard`,
        distinctName: 'userCredits',
        hasMembership: authWrapper?.userInfoData?.hasMembership,
        paymentMode: 'payment',
        userCredsQty: Number(planObj?.quantity),
        productId: planObj?.gameId,
        planId: planObj?.planId,
        //@ts-ignore
        tolt_referral: window?.tolt_referral,
      })
    )
    window.open(checkoutSessionResponse?.payload?.payload?.data?.url, '_self')
  }

  const changeConnectCommonEvent = async (event: any) => {
    const res = await dispatch(
      editUserInfo({
        commonEventAttachedWith: event.target.value,
      })
    )
    if (res?.payload?.data?.status === 'success') {
      authWrapper.setUserInfoData((prev: any) => ({
        ...prev,
        commonEventAttachedWith: event.target.value,
      }))
      successToast('Configuration saved!')
    } else {
      errorToast('Something went wrong!')
    }
  }

  const copyCommonSlideshowLink = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_WEBAPP_URL}config/sshow/play?commonEvent=${authWrapper?.userInfoData?.commonEventId}`
    )
    successToast('Link copied !')
  }

  const getSecurityNumberClickHandler = async () => {
    await dispatch(getSecurityNumber())
    setCommonSlideshowConfig((prev) => ({ ...prev, show: true }))
  }

  const hideSecurityNumbers = () =>
    setCommonSlideshowConfig((prev) => ({ ...prev, show: false }))

  const continueClickHandler = async () => {
    await dispatch(
      approveCommonSlideshowRequest({
        selectedNumber: commonSlideshowConfig.selectedNumber,
        connectionToken: productsData?.securityNumbers?._id,
      })
    )
    setCommonSlideshowConfig((prev) => ({ ...prev, show: false }))
  }

  const purchasedCredits = useUserCreditsPlans(true)
  const notPurchasedCredits = useUserCreditsPlans(false)

  return (
    <div className={`members-page-container container my-3`}>
      <div
        style={{
          display:
            isLoader ||
            productsData?.loading ||
            paymentSlice?.isPaymentApiLoading ||
            settings?.loading
              ? 'flex'
              : 'none',
        }}
        className="loader"
      >
        <Loader />
      </div>
      {!!openRemove && (
        <DashboardModals
          setIsLoader={setIsLoader}
          setOpenRemove={setOpenRemove}
          removeUrl={`${memberMailerAddedPrd?.prodcutMetadata?.createAPIurl?.url}?mailerId=${memberMailerAddedPrd?._id}`}
          openRemove={openRemove}
        />
      )}
      <div className="assets-btn-wrapper cursor-pointer">
        <a
          href="https://www.esstart.com/assets/instant-spotlight-media"
          rel="noopener noreferrer"
          target="_blank"
        >
          <button>View Assets</button>
        </a>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-1 px-lg-2 px-md-1 my-2 activity-card card-wrapper">
          <Card>
            <div className="card-content flex-grow-1">
              <div className="dashboard-qr-comp">
                <CommonQrWrapper />
              </div>
            </div>
            <CardFooter>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <Buttons
                  text="Manage"
                  bgColor="rgb(255, 114, 0)"
                  color="#fff"
                  borderRadius={true}
                  boxShadow={false}
                  padding="6px 10px"
                  onClick={() => navigate('/personalized-qr')}
                  fontSize="12px"
                  className="manage-btn"
                />
                <Buttons
                  text="Download QR"
                  bgColor="rgb(255, 114, 0)"
                  color="#fff"
                  borderRadius={true}
                  boxShadow={false}
                  padding="6px 10px"
                  onClick={onQrDownload}
                  fontSize="12px"
                  className="manage-btn"
                />
              </div>
            </CardFooter>
          </Card>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-1 px-lg-2 px-md-1 my-2 activity-card card-wrapper">
          {!!memberMailerPrd?._id ? (
            <Card>
              <CardMedia
                image={memberMailerPrd?.cardImgURL}
                alt={memberMailerPrd?.displayTitle}
              />
              <CardContent className="h-100 flex-grow-1">
                {!!memberMailerPrd?.startingPrice &&
                  !memberMailerAddedPrd?._id && (
                    <PriceLable
                      cardLable={true}
                      price={memberMailerPrd?.startingPrice}
                    />
                  )}
                <h5>{memberMailerPrd?.displayTitle}</h5>
                <div className="mt-2">
                  <p>{memberMailerPrd?.shortDesc1}</p>
                </div>
              </CardContent>
              <CardFooter>
                {!!memberMailerAddedPrd?._id ? (
                  <>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Buttons
                        text="Manage"
                        bgColor="rgb(255, 114, 0)"
                        color="#fff"
                        borderRadius={true}
                        boxShadow={false}
                        padding="6px 10px"
                        onClick={() =>
                          handleManageRedirection(memberMailerAddedPrd)
                        }
                        fontSize="12px"
                        className="manage-btn"
                      />
                      <Buttons
                        text="Remove"
                        bgColor="unset"
                        color="#FF0000"
                        borderRadius={false}
                        boxShadow={false}
                        padding="0"
                        fontSize="12px"
                        onClick={(e: any) => setOpenRemove(true)}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex align-items-center mb-3">
                      <Buttons
                        text="Add"
                        bgColor={`${'rgb(255, 114, 0)'}`}
                        color="#fff"
                        borderRadius={true}
                        boxShadow={false}
                        padding="8px 16px"
                        fontSize="12px"
                        className={`ms-2 test-drive-btn`}
                        onClick={() =>
                          (window.location.href = `/dashboard?productId=${memberMailerPrd?._id}`)
                        }
                      />
                    </div>
                    <p
                      style={{ fontSize: '12px', color: 'rgb(181, 181, 181)' }}
                    >
                      * Your purchase is refundable within 30 days of purchase.
                    </p>
                  </>
                )}
              </CardFooter>
            </Card>
          ) : (
            <></>
          )}
        </div>
        {!!authWrapper?.userInfoData?.enableCommonSlideshow && (
          <div className="col-lg-4 col-md-6 col-sm-1 px-lg-2 px-md-1 my-2 activity-card card-wrapper common-slideshow-config-card">
            <Card>
              <div className="d-flex flex-column justify-content-between align-items-center flex-grow-1">
                {!commonSlideshowConfig.show ? (
                  <>
                    <div className="card-content flex-grow-1">
                      <p className="fs-1_25rem text-center">
                        <b>Virtual Camera Spotlight Config</b>
                      </p>
                      <p className="text-center mt-1rem">
                        Select the event you want to stream to the virtual
                        camera
                      </p>
                      <div className="text-center mt-1rem">
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <Select
                            value={
                              authWrapper?.userInfoData?.commonEventAttachedWith
                            }
                            onChange={changeConnectCommonEvent}
                            displayEmpty
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {filteredSpotlightEvents?.length > 0 &&
                              filteredSpotlightEvents?.map((prd: any) => (
                                <MenuItem
                                  key={prd?._id}
                                  value={prd?.activityCode}
                                >
                                  {prd?.showName}: {prd?.activityCode}
                                </MenuItem>
                              ))}
                            {filteredSpotlightEvents?.length > 0 &&
                              filteredSpotlightEvents?.findIndex(
                                (prd: any) =>
                                  prd?.activityCode ===
                                  authWrapper?.userInfoData
                                    ?.commonEventAttachedWith
                              ) < 0 && (
                                <MenuItem
                                  value={
                                    authWrapper?.userInfoData
                                      ?.commonEventAttachedWith
                                  }
                                >
                                  Selected:{' '}
                                  {
                                    authWrapper?.userInfoData
                                      ?.commonEventAttachedWith
                                  }
                                </MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                      <div
                        className="copy-link-text mt-1rem d-flex gap-2 align-items-center justify-content-center cursor-pointer"
                        onClick={copyCommonSlideshowLink}
                      >
                        <p>Copy Link</p>
                        <img src={copyIcon} alt="copy" />
                      </div>
                    </div>
                    <button
                      className="app-pri-btn mb-24px"
                      onClick={getSecurityNumberClickHandler}
                    >
                      Get Security Number
                    </button>
                  </>
                ) : (
                  <div className="security-number-section d-flex flex-column justify-content-center align-items-center p-1rem flex-grow-1">
                    <div>
                      <p>
                        Please click on the number that you are seeing on the
                        slideshow
                      </p>
                      <div className="d-flex gap-4 justify-content-center mt-4">
                        {productsData?.securityNumbers?.numbers?.length > 0 &&
                          productsData?.securityNumbers?.numbers?.map(
                            (num: number, idx: number) => (
                              <div
                                className={`number-options d-flex justify-content-center align-items-center cursor-pointer ${
                                  commonSlideshowConfig.selectedNumber === num
                                    ? 'selected-number-option'
                                    : ''
                                }`}
                                key={idx}
                                onClick={() =>
                                  setCommonSlideshowConfig((prev) => ({
                                    ...prev,
                                    selectedNumber: num,
                                  }))
                                }
                              >
                                {num}
                              </div>
                            )
                          )}
                      </div>
                      {commonSlideshowConfig.selectedNumber && (
                        <div className="d-flex gap-4 justify-content-center mt-5">
                          <button
                            className="app-sec-btn"
                            onClick={hideSecurityNumbers}
                          >
                            Cancel
                          </button>
                          <button
                            className="app-pri-btn"
                            onClick={continueClickHandler}
                          >
                            Continue
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Card>
          </div>
        )}
      </div>

      {/* -------------------------------------------------------------------------------------------------------------------------- EXCLUSIVE COMBO SECTION --------------------------------------------------------------------------------------------------------------------------*/}

      {notPurchasedCredits?.length > 0 ? (
        <div className="user-credit-plan-wrapper">
          <p
            className={`heading-1 ${
              purchasedCredits?.length === 0 ? 'mb-5rem' : 'mb-1rem'
            }`}
          >
            Buy our exclusive combos
          </p>
          {purchasedCredits?.length > 0 && (
            <div className="credits-balance mb-5rem p-1rem d-flex gap-2">
              <p>
                <span>
                  <b>Credits Balance: </b>
                </span>
                {purchasedCredits?.map((creditsObj: any) => (
                  <span key={creditsObj?.id}>
                    {creditsObj?.productName}{' '}
                    <small>({creditsObj?.planName})</small> -{' '}
                    {creditsObj?.purchasedCreditsQuantity -
                      creditsObj?.usedCreditsQuantity}
                  </span>
                ))}
              </p>
            </div>
          )}

          <div className="flex-row gap-8 flex-wrap row-gap-4rem">
            {notPurchasedCredits?.map((planObj: any, idx: number) => (
              <div
                key={idx}
                className={`col-lg-3 col-md-6 col-sm-1 user-credit-plan d-flex flex-column gap-6 align-items-center ${
                  window.innerWidth < 770 ? 'card-wrapper' : ''
                }`}
              >
                <div className="ribbon-wrapper d-flex flex-column align-items-center">
                  <div className="ribbon1">
                    {planObj?.productName} - {planObj?.planName}
                  </div>
                  <div className="ribbon2">
                    Buy <span>{planObj?.quantity}</span> and Save $
                    {planObj?.savingsAmount}
                  </div>
                </div>
                <div>
                  <p className="price-label text-center">Price per unit</p>
                  <p className="item-price">
                    <span style={{ color: '#B5B5B5' }}>$</span>
                    <span>
                      {(planObj?.unit_amount / 100 / planObj?.quantity)
                        .toString()
                        .slice(0, 5)}
                    </span>
                  </p>
                </div>
                <div>
                  <p>
                    <b>Total purchase: ${planObj?.unit_amount / 100}</b>
                  </p>
                </div>
                <button
                  className="cursor-pointer"
                  onClick={() => purchasePlan({ planObj })}
                >
                  buy now
                </button>
                <div className="d-flex flex-column gap-1">
                  {planObj?.offers?.map((text: string, idx: number) => (
                    <p key={idx}>💎 {text}</p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
