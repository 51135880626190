import styled from 'styled-components'

export const MyDevicesStyles = styled.div`
  .my-devices.my-devices__layout {
    max-width: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 24px !important;
    box-sizing: border-box !important;
    padding: 0px !important;
    min-height: calc(100vh - 84px);
  }

  .my-devices__title-box {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 5px;
    min-height: 45px;
    border-bottom: 1px solid #e6e6ea;
    padding-bottom: 35.5px;
  }

  .my-devices__text.header {
    min-width: 143px;
    min-height: 30px;
    /* UI Properties */
    text-align: left;
    letter-spacing: 0px;
    color: #202020;
    opacity: 1;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
  }

  .my-devices__text.normal {
    min-height: 19px;
    text-align: left;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding-top: 5px;
  }

  .btn.add-devices {
    right: 51px;
    bottom: 61px;
    position: fixed;
    float: right;
    width: 70px;
    height: 70px;

    opacity: 1;
    cursor: pointer;
  }

  .my-devices__deviceslist-box {
    margin-left: 50px;
    margin-right: 50px;
    padding-top: 25.5px;
  }

  .loader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    background-color: #00000050;
    z-index: 1111;
    align-items: center;
    justify-content: center;
  }
`
