import React from 'react'
import EsstartRoutes from './routes'
import { Toaster } from 'react-hot-toast'
// import './assets/app.scss'
import { AppStyles } from './appStyles'

const App = () => {
  return (
    <AppStyles className="app app__wrapper">
      <EsstartRoutes />
      <Toaster />
    </AppStyles>
  )
}

export default App
