import styled from 'styled-components'

export const NoDevicesFoundStyles = styled.div`
  .my-devices__no-device-box {
    margin-left: auto;
    margin-right: auto;
    margin-top: 35.5px;
    width: 210px;
    min-height: 193px;
    // background: transparent
    //   url('../../../assets/images/svg/no-device-found.svg') 0% 0% no-repeat
    //   padding-box;
    opacity: 1;
  }
  .my-devices__no-device-underline {
    width: 429px;
    height: 1px;
    /* UI Properties */
    border-bottom: 1px solid #707070;
    opacity: 0.41;
    margin: 27px auto 19px auto;
  }
  .my-devices__no-device-header-text {
    width: 295px;
    min-height: 42px;
    text-align: left;
    letter-spacing: 0px;
    color: #202020;
    margin-left: auto;
    margin-right: auto;
    font: normal normal 400 34px/42px Plus Jakarta Sans;
  }
  .my-devices__no-device-sub-text {
    width: 100%;
    min-height: 19px;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
    font: normal normal 400 16px/19px Plus Jakarta Sans;
    letter-spacing: 0px;
    color: #202020;
    opacity: 1;
  }
`
