import { Box } from '@mui/material'
import { NoDevicesFoundStyles } from './style'
// import './no-devices-found.scss'
import noDeviceImgPlaceholder from '../../../assets/images/svg/no-device-found.svg'

export const NoDevicesFound = () => {
  return (
    <NoDevicesFoundStyles>
      <Box component="div" className={`my-devices__no-device-box`}>
        <img src={noDeviceImgPlaceholder} alt="no device" />
      </Box>
      <Box component="div" className={`my-devices__no-device-underline`} />
      <Box component="div" className={`my-devices__no-device-header-text`}>
        No Device Found
      </Box>
      <Box component="div" className={`my-devices__no-device-sub-text`}>
        No device added to My Device List, Please add a smart Device
      </Box>
    </NoDevicesFoundStyles>
  )
}
