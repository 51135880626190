import React, { useState } from 'react'
import { Tabs, Tab, Typography, Box, Breadcrumbs, Link } from '@mui/material'
import CredentialsTab from './CredentialsTab'
import PersonalInfoTab from './personalInfo'
import { CredentialsWrapper } from './style'
import { ThemeWrapper } from '../themes'
import { BillingTab } from './Billing'
import { TabPanel } from 'esstart-components'

const MyCredentials = () => {
  const [value, setValue] = useState<number>(0)

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const breadcrumbs = [
    <Link underline="hover" key="1" href="/" color="#009FB7" onClick={() => {}}>
      Account
    </Link>,
    <Typography key="2">My Profile</Typography>,
  ]

  return (
    <ThemeWrapper>
      <CredentialsWrapper>
        <Box component="div" className={`my-credentials__title-box`}>
          <Typography variant="h5">Manage Profile</Typography>
          <Breadcrumbs separator=">" sx={{ marginTop: '10px' }}>
            {breadcrumbs}
          </Breadcrumbs>
          <Typography sx={{ color: '#707070', marginTop: '4px' }}>
            You can manage your name and mobile number here.
          </Typography>
        </Box>
        {/* <Box
          sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '35px' }}
        >
          <Tabs
            value={value}
            onChange={onTabChange}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="Personal Info" />
            <Tab label="Credentials" />
            <Tab label="Billing" />
          </Tabs>
        </Box>
        <div
          role="tabpanel"
          hidden={value !== 0}
          id={`simple-tabpanel-${0}`}
          aria-labelledby={`simple-tab-${0}`}
        >
          <PersonalInfoTab />
        </div>
        <div
          role="tabpanel"
          hidden={value !== 1}
          id={`simple-tabpanel-${1}`}
          aria-labelledby={`simple-tab-${1}`}
        >
          <CredentialsTab />
        </div>
        <div
          role="tabpanel"
          hidden={value !== 2}
          id={`simple-tabpanel-${2}`}
          aria-labelledby={`simple-tab-${2}`}
        >
          <BillingTab />
        </div> */}
        <TabPanel
          showDropdownForSmallScreen={true}
          accentColor="#009FB7"
          arrowColor="#ffffff"
          className="mt-4"
          tabs={[
            {
              title: <span>Personal Info</span>,
              render: () => (
                <div className="tab-child">
                  <h2 className="tab-child__heading">Personal Info</h2>
                  <div className="tab-child__content">
                    <PersonalInfoTab />
                  </div>
                </div>
              ),
            },
            {
              title: <span>Credentials</span>,
              render: () => (
                <div className="tab-child">
                  <h2 className="tab-child__heading">Credentials</h2>
                  <div className="tab-child__content">
                    <CredentialsTab />
                  </div>
                </div>
              ),
            },
            {
              title: <span>Billing</span>,
              render: () => (
                <div className="tab-child">
                  <h2 className="tab-child__heading">Billing</h2>
                  <div className="tab-child__content">
                    <BillingTab />
                  </div>
                </div>
              ),
            },
          ]}
        />
      </CredentialsWrapper>
    </ThemeWrapper>
  )
}

export default MyCredentials
